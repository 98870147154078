function projects() {

	var bxSlider = null;

	$('.projects-filter').on('change', '.filter-list__options', function(e) {
		loadProjects($('.filter-years a.active').text(), $(this).val(), $('.filter-limit').attr('data-limit'));
	});
	$('.filter-event').click(function(e) {
		// e.preventDefault();

		if($(this).hasClass('step-item__next')) {

			let year = parseInt($('.filter-years .filter-group a.active').attr('data-year'));
			$('.filter-years .filter-group a.active').removeClass('active');
			$('.filter-years .filter-group a[data-year='+(year+1)+']').addClass('active');

		}

		if($(this).hasClass('step-item__prev')) {

			let year = parseInt($('.filter-years .filter-group a.active').attr('data-year'));
			$('.filter-years .filter-group a.active').removeClass('active');
			$('.filter-years .filter-group a[data-year='+(year-1)+']').addClass('active');
		}

		$(this).parent().find('a').removeClass('active');
		$(this).not('.filter-select__step a').addClass('active');

		$('.filter-years .filter-select__year').text($('.filter-years .filter-group a.active').text());

		if ($('.filter-years .filter-group a.active').attr('data-year') == $('.filter-years .filter-group a:first-child').attr('data-year')) {
			$('.step-item__prev').fadeOut(0);
		}else{
			$('.step-item__prev').fadeIn(0);
		}

		if($('.filter-years .filter-group a.active').attr('data-year') == $('.filter-years .filter-group a:last-child').attr('data-year')){
			$('.step-item__next').fadeOut(0);
		}else{
			$('.step-item__next').fadeIn(0);
		}

		loadProjects($('.filter-years a.active').text(), $('.filter-department a.active').attr('data-dep'), $('.filter-limit').attr('data-limit'));

		return false;
	});


	this.init = function() {

		if ($('.filter-years .filter-group a.active').attr('data-year') == $('.filter-years .filter-group a:first-child').attr('data-year')) {
			$('.step-item__prev').fadeOut(0);
		}else{
			$('.step-item__prev').fadeIn(0);
		}

		if($('.filter-years .filter-group a.active').attr('data-year') == $('.filter-years .filter-group a:last-child').attr('data-year')){
			$('.step-item__next').fadeOut(0);
		}else{
			$('.step-item__next').fadeIn(0);
		}

		if ($(window).width() <= 575) {
			bxSlider = $('.projects-items').bxSlider({
				pager: false,
				controls: true,
				preventDefaultSwipeX: false,
				preventDefaultSwipeY: false,
				touchEnabled: false,
				oneToOneTouch: false,
			});
		}
	}



	function loadProjects(year, departaments, limit) {
		let locale = $('html').attr('lang');
	    $.ajax({
	        url: '/'+locale+"/api/v1/ajax/getProjects",
	        type: "POST",
	        cache: false,
	        data:{department:departaments,year:year,limit:limit},
	        beforeSend: function() {
	        	
	            $('.projects-items .projects-item').remove();

	            if (bxSlider !== null) {
	            	bxSlider.destroySlider();
	            	bxSlider = null;
	            }
	        },
	        success: function(response){

	        	$('.projects-items').append(response);
	        	
	        },
	        complete: function() {
	        	$('.projects-info').mCustomScrollbar({
		            scrollInertia: 100,
		            theme: "dark"
		        });

				if ($(window).width() <= 575) {
					if ($('.projects-item').length != 0) {
						bxSlider = $('.projects-items').bxSlider({
							pager: false,
							controls: true,
							preventDefaultSwipeX: false,
							preventDefaultSwipeY: false,
							touchEnabled: false,
							oneToOneTouch: false,
						});

						$('.projects-info__sm').mCustomScrollbar({
				            scrollInertia: 100,
				            theme: "dark"
				        });
					}
				}
	        }
	    });  
	}


}

$(document).ready(function () {
	new projects().init();

	if ($(window).width() <= 575) {
		$('.projects-info__sm').mCustomScrollbar({
	        scrollInertia: 100,
	        theme: "dark"
	    });
	} else {
		$('.projects-info').mCustomScrollbar({
	        scrollInertia: 100,
	        theme: "dark"
	    });
	}
	
});
